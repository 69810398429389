<template>
	<div class="bg">
		<img class="bgimg" :src="'./images/baohao.jpg'" />
		
	</div>
</template>

<script>

export default {

	data() {
		return {
			cardid: 0,
			who: 0,
			images: []

		}
	},
	mounted() {
		document.title = "中国移动-大流量卡在线开卡"

		this.cardid = this.$Request("g")//goodid
		this.who = this.$Request("f")//fromwhere
		

	},
	methods: {
		

	}
}
</script>

<style scoped>
.bg {
	height: 100vh;
	overflow: auto;
	background-size: auto 100%;
	background-position: center;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
	background-color: #f7f7f7;
}

.bgimg {
	width: 100%;
	vertical-align: middle;
}

.inpage {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;

}

.contentbox {
	width: 86%;
	margin: 0 auto;
}


.dttable,
.dttable tr th,
.dttable tr td {
	border: 1px solid #63a1bc;
	border-collapse: collapse;
	-webkit-print-color-adjust: exact;
	-moz-print-color-adjust: exact;
	color-adjust: exact;
	padding: 10px;
	 text-align: center;
	
}

.dttable {
	width: 100%;
	border-radius: 10px;
}

.tdheader {
	background-color: #0f8aea;
	text-align: center;
	color: #fff;

}
</style>