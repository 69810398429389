import { render, staticRenderFns } from "./baohao.vue?vue&type=template&id=681a707b&scoped=true"
import script from "./baohao.vue?vue&type=script&lang=js"
export * from "./baohao.vue?vue&type=script&lang=js"
import style0 from "./baohao.vue?vue&type=style&index=0&id=681a707b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681a707b",
  null
  
)

export default component.exports